<template>
  <div>
    <h1 class="mb-30">Справочник</h1>

    <ul class="card-list">
      <li
        v-for="(item, index) in handbookList"
        :key="index"
        class="card-list__item"
      >
        <router-link
          class="card-list__link"
          :to="{ name: 'HandbookUpdate', params: { handbookName: item.name } }"
        >
          {{ item.pageTitle }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { HANDBOOK_LIST } from '@/const/handbookList'

export default {
  computed: {
    handbookList () {
      return Object.values(HANDBOOK_LIST)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-list {
  @include df(flex-start, flex-start, wrap);
  display: grid;
  grid-template-columns: repeat(4, minmax(140px, 350px));
  gap: 20px;

  &__item {
    height: 100%;
    min-height: 120px;
  }

  &__link {
    @include df(center, center);
    height: 100%;
    border-radius: 20px;
    padding: 10px 40px;
    @include font20;
    text-align: center;
    background: $color__white;
    box-shadow: $color__card-box-shadow;
    transition: $transition;

    @media screen and (min-width: $hover-enable) {
      &:focus-visible,
      &:hover {
        color: $color__main;
        box-shadow: 0 0 0 1px $color__main;
      }
    }
  }
}
</style>
